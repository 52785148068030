import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { createProfile } from 'api/profiles';
import Spinner from 'components/Spinner';

const ProfileDirector = () => {
  useEffect(() => {
    (async () => {
      const { profile } = await createProfile({
        displayName: 'Nouveau profil',
      });
      navigate(`/profile-walkthrough/${profile.id}`, { replace: true });
    })();
    // eslint-disable-next-line
  }, []);

  return <Spinner />;
};

export default ProfileDirector;
