import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import useProfileEditor from 'hooks/profileEditor';

const StepDirector = ({ profileId }) => {
  const { steps, lastCompletedStep, isReady } = useProfileEditor({ profileId });

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (lastCompletedStep) {
      const pageName = steps[lastCompletedStep - 1].slug;
      navigate(`/profile-walkthrough/${profileId}/${pageName}`);
    } else {
      const pageName = steps[steps.length - 1].slug;
      navigate(`/profile-walkthrough/${profileId}/${pageName}`);
    }
  }, [isReady, lastCompletedStep, profileId, steps]);

  return <div />;
};

export default StepDirector;
