import React from 'react';
import { Formik } from 'formik';
import { Form } from 'components/Forms';
import styled from 'styled-components';
import isString from 'lodash/isString';
import { media, Buttons, Button, Heading } from 'ui';
import { GridContainer } from 'ui/containers';
import FormValues from 'components/FormValues';
import { useTranslation } from 'react-i18next';
import backgroundProfil from '../assets/images/backgroundProfil.jpg';
import { CheckboxBlock, CheckboxText } from './Footer';
import CheckboxField from 'components/Checkbox';

const Wrapper = styled.div`
  position: relative;
  display: block;
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing(6)};

  ${media.tablet`
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  `}
`;

const BlockForm = styled.div`
  margin-top: -16px;
  box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  background-color: #ffffff;
  transform: translateY(-5%);
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()};
  border-radius: 2px;
  font-size: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(3)};
  `}
`;

const NaturalTextForm = styled.div`
  width: 100%;
  font-size: 20px;

  > * {
    display: inline-block;
    margin-bottom: ${({ theme }) => theme.spacing()};
    margin-left: ${({ theme }) => theme.spacing(0.5)};
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  p {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing()};
  }

  ${media.tablet`
    font-size: 24px;
  `}
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.textLighter};
  font-size: 16px;
`;

const Header = styled.div`
  text-align: center;

  > * {
    margin-bottom: ${({ theme }) => theme.spacing()};
  }
`;

const BackgroundPicture = styled.div`
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    url("${backgroundProfil}");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

const ProfilePage = ({
  initialValues,
  fragments,
  title,
  subtitle,
  isGrid,
  onChange,
  onNextStep,
  slug,
}) => {
  const choices = (
    <>
      {fragments.map(fragment => {
        if (isString(fragment)) {
          return <p>{fragment}</p>;
        }
        return fragment;
      })}
    </>
  );

  const { t } = useTranslation();

  const isFinalStep = slug === 'finalize';

  return (
    <BackgroundPicture>
      <Wrapper>
        <BlockForm>
          {(title || subtitle) && (
            <Header>
              {title && <Heading>{title}</Heading>}
              {subtitle && <SubTitle>{subtitle}</SubTitle>}
            </Header>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onNextStep}
          >
            {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <FormValues onChangeValues={onChange} />

                {!isGrid ? (
                  <NaturalTextForm>{choices}</NaturalTextForm>
                ) : (
                  <GridContainer hasTopMargin variant="locations">
                    {choices}
                  </GridContainer>
                )}
                {isFinalStep && (
                  <>
                    <input type="hidden" name="join" value="777029" />
                    <CheckboxBlock style={{ marginTop: '32px' }}>
                      <CheckboxField name="consent[]" value="1756-2-fr" />
                      <CheckboxText>{t('footer.agreed')}</CheckboxText>
                    </CheckboxBlock>
                  </>
                )}
                <div>
                  <Buttons>
                    <Button
                      variant="primary"
                      disabled={isFinalStep && !Boolean(values.consent)}
                      type="submit"
                    >
                      {t('buttons.next')}
                    </Button>
                  </Buttons>
                </div>
              </Form>
            )}
          </Formik>
        </BlockForm>
      </Wrapper>
    </BackgroundPicture>
  );
};

export default ProfilePage;
