import React, { useContext } from 'react';
import { Match } from '@reach/router';
import { navigate } from '@reach/router';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import styled from 'styled-components';
import { Select } from 'components/Select';
import { Container } from 'ui/containers';
import { Title } from 'ui';
import { TextLink } from 'ui';
import ProfilePage from 'components/ProfilePage';
import useProfileEditor from 'hooks/profileEditor';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import PageContext from 'contexts/page';

const ProgressLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  top: -2px;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 2px;
  right: 0;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  max-width: ${props => props.progress}%;

  &:before {
    content: '';
    position: absolute;
    top: -9px;
    left: -2px;
    height: 20px;
    border-left: 2px solid ${({ theme }) => theme.primary};
  }

  &:after {
    position: absolute;
    top: -9px;
    right: 0;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.primary};
  }
`;

const StepSelector = ({ stepOptions }) => (
  <Match path="/profile-walkthrough/:profileId/:step">
    {({ match }) => {
      const { profileId, step } = match || {};
      return (
        <Select
          variant="search"
          options={stepOptions}
          onChange={({ value }) =>
            navigate(`/profile-walkthrough/${profileId}/${value}`)
          }
          value={step}
        />
      );
    }}
  </Match>
);

const ProfileSteps = ({ profileId, step }) => {
  const { getLanguagePrefix } = useContext(PageContext);
  const { profile, steps } = useProfileEditor({ profileId });
  const currentStep = find(steps, { slug: step });
  const { t } = useTranslation();

  const getIndex = step => findIndex(steps, { slug: step });
  const getNextStep = step => (steps[getIndex(step) + 1] || {}).slug;

  const handleNextStep = () => {
    if (getNextStep(step)) {
      navigate(`/profile-walkthrough/${profileId}/${getNextStep(step)}`, {
        replace: true,
      });
    } else {
      navigate(getLanguagePrefix(t('url.account')), { replace: true });
    }
  };

  return (
    <>
      <Container>
        <TextLink
          variant="black"
          to={`${getLanguagePrefix(t('url.account'))}/${profileId}`}
        >
          <MdKeyboardArrowLeft size={22} />
          {t('buttons.back')}
        </TextLink>
        <Title>{profile.displayName}</Title>
      </Container>

      <div style={{ position: 'relative' }}>
        <ProgressLine />
        <ProgressBarWrapper>
          <ProgressBar progress={30} />
        </ProgressBarWrapper>
      </div>

      <Container>
        <div style={{ maxWidth: 'fit-content' }}>
          <StepSelector
            stepOptions={steps.map(({ slug, label }) => ({
              label,
              value: slug,
            }))}
          />
        </div>
      </Container>

      <ProfilePage {...currentStep} onNextStep={handleNextStep} />
    </>
  );
};

export default ProfileSteps;
