import React from 'react';
import { Router } from '@reach/router';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ProfileDirector from 'components/ProfileWalkthrough/ProfileDirector';
import StepDirector from 'components/ProfileWalkthrough/StepDirector';
import ProfileSteps from 'components/ProfileWalkthrough/ProfileSteps';
import { useAuthentication } from 'hooks';
import { useDataLayerBuilder } from '@tymate/react-gtm';

const ProfileWalkthrough = () => {
  const { t } = useTranslation();
  useAuthentication();
  useDataLayerBuilder(() => ({
    pageType: 'profileCreation',
  }));

  return (
    <>
      <Layout>
        <SEO title={t('pageTitle.profile')} />
        <Router>
          <ProfileSteps path="/profile-walkthrough/:profileId/:step" />
          <StepDirector path="/profile-walkthrough/:profileId" />
          <ProfileDirector path="/profile-walkthrough" />
        </Router>
      </Layout>
    </>
  );
};

export default ProfileWalkthrough;
